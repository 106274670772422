import React from "react";
import "../assets/css/layout-presets.css";

const LayoutPresets = ({ layoutContent }) => {
    const { TITULAR, FECHA, LAYOUT, IMAGEN, CONTENIDO } = layoutContent;

    // Función para renderizar el contenido en función del tipo
    const renderContenido = (item, index) => {
        if (item.TIPO === "texto") {
            return (
                <div
                    key={index}
                    dangerouslySetInnerHTML={{ __html: item.VALOR }}
                />
            );
        } else if (item.TIPO === "img") {
            return <img key={index} src={item.VALOR} alt={item.ALT} width={item.ANCHO} height={item.ALTO} />;
        }
        return null;
    };

    // Diferentes presets de layout
    const renderLayout = () => {
        switch (LAYOUT) {
            case "columnas":
                return (
                    <div className="layout-preset">
                        <div className="header__wrapper">
                            <div className="header__content">
                                <h2>{TITULAR}</h2>
                                <h3>{FECHA}</h3>
                            </div>
                            {IMAGEN && <img src={IMAGEN} alt="Header" />}
                        </div>
                        <div className="horizontal-divider"></div>
                        <div className="content__wrapper--column">
                            {CONTENIDO.map((item, index) => renderContenido(item, index))}
                        </div>
                    </div>
                );
            case "bloques":
                return (
                    <div className="layout-preset">
                        <div className="header__wrapper">
                            <div className="header__content">
                                <h2>{TITULAR}</h2>
                                <h3>{FECHA}</h3>
                            </div>
                            {IMAGEN && <img src={IMAGEN} alt="Header" />}
                        </div>
                        <div className="horizontal-divider"></div>
                        <div className="content__wrapper--row">
                            {CONTENIDO.map((item, index) => renderContenido(item, index))}
                        </div>
                    </div>
                );
            default:
                return <p>Selecciona un layout válido ("bloques" o "columnas")</p>;
        }
    };

    return renderLayout();
};

export default LayoutPresets;
